import { cloneDeep as _cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { fieldTypes } from '../utils/validator';
let isRequired = true;
let isOptional = false;

export const acctObject = {
  name: fieldTypes.Text(null , 'Account Name', isRequired, '', { minLen: 5, maxLen: 50 }),
  desc: fieldTypes.Text(null, 'Description', isOptional, '', { maxLen: 100 }),
  code: fieldTypes.Text(null, 'Account Short Code', isRequired, '', { minLen: 5, maxLen: 32, noSpaces: true, accountCode: true }),
  curr: fieldTypes.Text(null, 'Currency', isRequired, 'CAD'),
  type: fieldTypes.Text(null, 'Account Type', isRequired, 'DEF'),
  status: fieldTypes.Text(null, 'Status', isRequired, 'ac'),
  date: fieldTypes.Date(null, 'Date'),
  url: fieldTypes.Text(null, 'URL', isOptional, '', {isURL: true}),
};


export const mapAccount = (_account) => {
  let ac = _cloneDeep(acctObject);
  if (!_account) {
      return ac;
  }
  let {code, curr, date, name, status, type, desc, url} = _account;
  if (code) ac.code.value = code;
  if (curr) ac.curr.value = curr;
  ac.date.value = (date) ? date.substr(0,10) : moment().format('YYYY-MM-DD');
  if (name) ac.name.value = name;
  if (desc || desc === '') ac.desc.value = desc;
  if (status) ac.status.value = status;
  if (type) ac.type.value = type;
  if (url) ac.url.value = url;
  return ac;
}



export const accountCCStmtSummaryObject = {
  limit: fieldTypes.Amount('limit', 'Credit Limit', isRequired, 1000 ),
  available: fieldTypes.Amount('available', 'Available Credit', isRequired, 1000),
  last_statement_date: fieldTypes.Date('last_statement_date', 'Last Statement Date'),
  last_statement_due: fieldTypes.Date('last_statement_due', 'Due On', isOptional, moment().add(45, 'days').format('YYYY-MM-DD')),
  last_statement_min: fieldTypes.Amount('last_statement_min', 'Minimum Due'),
  last_statement_bal: fieldTypes.Amount('last_statement_bal', 'Total Due'),
  last_payment_date: fieldTypes.Date('last_payment_date', 'Last Payment Date'),
  last_payment_amnt: fieldTypes.Amount('last_payment_amnt', 'Last Payment Amount')
}

export const accountCCStmtScheduleObj = {
  limit: fieldTypes.Amount('limit', 'Credit Limit', isRequired, 1000 ),
  available: fieldTypes.Amount('available', 'Available Credit', isRequired, 1000 ),
  stmt_start_date: fieldTypes.Date('stmt_start_date', 'Statement Start Date'),
  frequency: fieldTypes.List('frequency', 'Frequency', isRequired, 'monthly', { inList: [{
    code: 'monthly',
    name: 'Monthly',
    inc: 'Months',
    default: true
  },
  // {
  //   code: 'quarterly',
  //   name: 'Quarterly',
  //   inc: 'Quarters'
  // }
  ]} ),
  statement_day: fieldTypes.Number('statement_day', 'Statement Date (day of the month)', isRequired, 1, { minNum: 1, maxNum: 28 }),
  next_statement_date: fieldTypes.Date('next_statement_date', 'Next Statement Date', isRequired),
  due_grace_period: fieldTypes.Number('due_grace_period', 'Payment Due Grace Period (No. of days)', isRequired, 25, { minNum: 0, maxNum: 99 }),
  next_payment_due_date: fieldTypes.Date('next_payment_due_date', 'Next Payment Due Date', isRequired),
  pu_rate: fieldTypes.Percent('pu_rate', 'Purchases % (0.00)', isRequired, 24.00),
  ca_rate: fieldTypes.Percent('ca_rate', 'Cash Adv % (0.00)', isRequired, 24.00),
  bt_rate: fieldTypes.Percent('bt_rate', 'Bal Transfer % (0.00)', isRequired, 24.00),

  /** An annual percentage rate (APR) of 24% indicates that if you carry -
  |*   a balance on a credit card for a full year, the balance will increase -
  |*   by approximately 24% due to accrued interest. For instance, if you maintain -
  |*   a $1,000 balance throughout the year, the interest accrued would amount to -
  \*   around $240.00. **/
  apr_rate: fieldTypes.Percent('apr_rate', 'APR % (0.00)', isRequired, 24.00),

  // Annaul Fee
  annual_fee_flag: fieldTypes.Checkbox('annual_fee_flag', 'Annual Fee Flag', isRequired, true),
  annual_fee: fieldTypes.Amount('annual_fee', 'Annual Fee', isOptional, 49),
  annual_fee_on: fieldTypes.Date('annual_fee_on', 'Next Annual Fee Date')
}



export const mapAccountSchedule = (_accountScheduleInfo) => {
  let ac = _cloneDeep(accountCCStmtScheduleObj);
  if (_accountScheduleInfo) {
      let {
        limit,
        available,
        stmt_start_date,
        frequency,
        statement_day,
        next_statement_date,
        due_grace_period,
        next_payment_due_date,
        pu_rate,
        bt_rate,
        ca_rate,
        apr_rate,
        annual_fee_flag,
        annual_fee,
        annual_fee_on
      } = _accountScheduleInfo;
      ac.limit.value = limit;
			ac.available.value = available;
			ac.stmt_start_date.value = stmt_start_date;
			ac.frequency.value = frequency;
			ac.statement_day.value = statement_day;
			ac.next_statement_date.value = next_statement_date;
			ac.due_grace_period.value = due_grace_period;
			ac.next_payment_due_date.value = next_payment_due_date;
			ac.pu_rate.value = pu_rate;
			ac.bt_rate.value = bt_rate;
			ac.ca_rate.value = ca_rate;
			ac.apr_rate.value = apr_rate;
			ac.annual_fee_flag.value = annual_fee_flag;
      //override default value only if already entered.
      if (annual_fee_flag) {
        ac.annual_fee.value = annual_fee;
        ac.annual_fee_on.value = annual_fee_on;  
      }
      return ac;
  } else {
      return ac;
  }
}
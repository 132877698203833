import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';
// import SweetAlert from 'sweetalert2-react';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, LinearProgress, FormControl, Dialog, InputLabel, Checkbox } from '@material-ui/core';

import './Tran.css'
import { validator } from '../utils/validator';
import {TranStyle} from './TranStyle';
import { tranObject, mapTran } from './tranObject';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import mapStateToProps from '../actions/stateToProps';
import moment from 'moment-timezone';

let classes = null;
// console.log('tranobect', tranObject);

class NewTran extends Component {
	constructor(props) {
		super(props)
		classes = this.props.classes;
		this.handleFieldValidation = this.handleFieldValidation.bind(this)
        this.handleBulkFieldChange = this.handleBulkFieldChange.bind(this)
        this.handleFrequencyEndChange = this.handleFrequencyEndChange.bind(this)
        this.handleFrequencyChange = this.handleFrequencyChange.bind(this)
	}

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		sqlErrorMessage: '',
		transaction          : this.props.transactions[0] ? mapTran(this.props.transactions[0]) : _cloneDeep(tranObject),
		multiUpdateField: 'type',
        transactions: this.props.transactions || [],
        repeatFlag: false,
        frequencyList: [{
            code: 'daily',
            name: 'Daily',
            inc: 'days'
        },{
            code: 'weekly',
            name: 'Weekly',
            inc: 'weeks'
        },{
            code: 'monthly',
            name: 'Monthly',
            inc: 'months'
        }],
        frequency: 'daily',
        frequencyInc: 'days',
        freqencyEndDate: this.props.transactions[0] ? this.props.transactions[0].date.value : tranObject.date.value,
	};

	handleAllFieldValidation = () => {
		let _transaction = this.state.transaction;
		let allPassed = true;
		Object.keys(_transaction).forEach((name) => {
			let _field = _transaction[name];
			let rules = Object.keys(_field.rules);

			if (rules) {
				for (let rule of rules) {
					if (_field.rules[rule] && rule in validator) {
						let validation = validator[rule](_field.value, _field.rules[rule]);

						if (!validation.passed) {
							_field.hasErrors.error = true;
							_field.hasErrors.message = validation.message;
							allPassed = false;

							break;
						}
						else {
							_field.hasErrors.error = false;
							_field.hasErrors.message = validation.message;
						}
					}
				}
			}
		})

		this.setState({
			transaction: _transaction
		});
		if (allPassed) {
			this.handleCreateTransaction();
		}
	}

	handleFieldValidation(e) {
		let _transaction = this.state.transaction;
		let _field = _transaction[e.target.name];
		let rules = Object.keys(_field.rules);

		_field.value = e.target.value;
		// console.log('_field', _field)

		if (rules) {
			for (let rule of rules) {
				// console.log('rule', rule, '_field.rules[rule]', _field.rules[rule])
				if (_field.rules[rule] && rule in validator) {
					let validation = validator[rule](_field.value, _field.rules[rule]);

					if (!validation.passed) {
						_field.hasErrors.error = true;
						_field.hasErrors.message = validation.message;

						break;
					}
					else {
						_field.hasErrors.error = false;
						_field.hasErrors.message = validation.message;
					}
				}
			}
		}

		this.setState({
			transaction: _transaction
		});
	};

	handleBulkFieldChange(e) {
		console.log('e.target', JSON.stringify(e.target));
		this.setState({ multiUpdateField: e.target.value})
	}

	prepareReleaseObjectForSaving = (_transaction = null) => {
		let preparingReleaseObject = {};
		let _keys = Object.keys(_transaction);
		for (let key of _keys) {
			if (key !== '_id') {
				preparingReleaseObject[key] = _transaction[key].value;
			}
		}
		preparingReleaseObject.account = this.props.account._id;
		preparingReleaseObject.curr = this.props.account.curr;
		// if (_transaction._id) preparingReleaseObject._id = _transaction._id;
		preparingReleaseObject.qtr = Constants.getQtrStr(_transaction.date.value);
		return preparingReleaseObject;
	};


	handleCreateTransaction = async () => {
		let _transaction = this.state.transaction;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		let preparedReleaseObject = this.prepareReleaseObjectForSaving(_transaction);
        let responseErr = '';
        let isAfter = false;
        try {
            isAfter = moment(this.state.freqencyEndDate).isAfter(this.state.transaction.date);
        } catch (error) {
            console.error(error);
        }
        if (this.state.repeatFlag && isAfter) {
            //console.log('valid frequency end date found')
            preparedReleaseObject.repeat = "Y";
            preparedReleaseObject.schedule = [];
            let current = this.state.transaction.date;
            let max = 24;
            let index = 0;
            do {
                index++;
                let next = moment(current).add(1, this.state.frequencyInc).format("YYYY-MM-DD");
				//console.log(`next ${next} current ${current}`)
                preparedReleaseObject.schedule.push(next);
                isAfter = moment(this.state.freqencyEndDate).isAfter(next);
				//console.log(`isAfter ${isAfter}`)
                current = next;
				//console.log(`current: ${current}`)
            } while (isAfter && index < max);
            if (isAfter) {
                this.props.enqueueSnackbar(`Max ${max} transactions can be added at once.`, {
				    variant : 'info'
			    });
            }
            //console.log("preparedReleaseObject.schedule: ", JSON.stringify(preparedReleaseObject.schedule));
        } else {
            //console.log('invalid frequency end date found')
        }

		await getInstance()
			.post(`${Constants.URLs.TRANS}`, preparedReleaseObject)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Transaction created successfully', {
						variant : 'success'
					});
					_transaction = _cloneDeep(tranObject)
				}
			})
			.catch((error) => {
				console.log(error);
                responseErr = error.message;
                let msg = 'An error occurred from the server';
                try {
                    msg = error.response.data.message
                } catch (error) {}

				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					transaction: _transaction,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	};

	handleUpdateTransaction = async () => {
		let _transaction = this.state.transaction;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		let preparedReleaseObject = this.prepareReleaseObjectForSaving(_transaction);
		// console.log('preparedReleaseObject', preparedReleaseObject)
		let responseErr = '';
		await getInstance()
			.patch(`${Constants.URLs.TRANS}/${_transaction._id}`, preparedReleaseObject)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Transaction updated successfully', {
						variant : 'success'
					});
					_transaction = _cloneDeep(tranObject)
				}
			})
			.catch((error) => {
				console.log(error);
                let msg = 'An error occurred from the server';
                try {
                    if (error.response.data.message) msg = error.response.data.message
                } catch (error) {}
                responseErr = msg;
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					transaction: _transaction,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	}

	handleBulkUpdateTransaction = async () => {
		let {multiUpdateField, transactions, transaction} = this.state;
		let ids = transactions.reduce((arr, tran)=>{
			arr.push(tran._id);
			return arr;
		}, []);
		let payload = {
			ids,
			updates: {	
				[multiUpdateField] : transaction[multiUpdateField].value
			}
		}
		// console.log('bulk update payload', payload);
		await getInstance()
			.post(`${Constants.URLs.TRANS}/bulkupdate/ac/${this.props.account._id}`, payload)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
						variant : 'error'
					});
				}
				else {
					this.props.enqueueSnackbar('Transaction updated successfully', {
						variant : 'success'
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('An error occurred from the server', {
					variant : 'error'
				});
			})
			.finally(()=>{
				this.props.onSubmit();
			})
	}

	handleBulkAccountUpdateTransaction = async () => {
		let { multiUpdateField, transaction } = this.state;
		let payload = {
			account: this.props.account._id,
			updates: {
				[multiUpdateField]: transaction[multiUpdateField].value
			}
		}
		await getInstance()
			.post(`${Constants.URLs.TRANS}/bulkaccountupdate/ac/${this.props.account._id}`, payload)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
						variant: 'error'
					});
				} else {
					this.props.enqueueSnackbar('Transactions updated successfully', {
						variant: 'success'
					});
				}
			}).catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('An error occurred from the server', {
					variant: 'error'
				});
			}).finally(() => this.props.onSubmit())
	}


	/**
	 * Render UI components
	 */

	renderFormTitle() {
		let title = '';
		if (this.props.multiMode) {
			title = this.props.transactions.length === 0 ? 'Bulk Account Update in' : 'Bulk Update in';
		} else {
			title = this.props.actionUpdate ? 'Update ' : 'Add new ';
			title += this.props.isCredit ? 'credit' : 'debit';
			title += ' transaction in';
		}
		return (
			<Grid item md={12} lg={12} xs={12} style={{marginBottom: "5px"}}
			data-cy="newTranTitle">
				<Typography align="center">
					{title}
				</Typography>
				<Typography variant="h5" align="center">
					{this.props.account.name}
				</Typography>
			</Grid>
		)
	}

	renderFormBulkFieldSelection() {
		let fields = [{
			code: 'type', 
			name: 'Type'
		},{
			code: 'desc', 
			name: 'Description'
		},{
			code: 'note', 
			name: 'Note'
		},{
			code: 'date', 
			name: 'Date'
		}]
		return (
			<Grid item md={12} lg={12} xs={12} style={{ marginTop: "10px"}}>
				<FormControl className={classes.tranAccountSelect}>
				<InputLabel>Transaction Type:</InputLabel>
				<Select
					label='Chose Field:'
					name={'field'}
					value={this.state.multiUpdateField}
					onChange={this.handleBulkFieldChange}>
					{fields.map((field, index) => {
						return (
							<MenuItem
								key={`${field.code}`}
								value={field.code}>
								{`${field.name}`}
							</MenuItem>
						);
					})}
				</Select>
				</FormControl>
			</Grid>
		)
	}

	renderFormFieldAmnt() {
		let field = this.props.isCredit ? this.state.transaction.cr : this.state.transaction.dr;
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={field.id}
					label={field.alias}
					className={classes.textField}
					margin="normal"
					value={field.value}
					error={field.hasErrors.error}
					required={true}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={field.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderFormFieldType() {
		return (
			<Grid item md={12} lg={12} xs={12} style={{ marginTop: "10px"}}>
				<FormControl className={classes.tranAccountSelect}>
				<InputLabel>Transaction Type:</InputLabel>
				<Select
					label='Transaction Type:'
					name={'type'}
					data-cy="ddTranSelectType"
					value={this.state.transaction.type.value}
					onChange={this.handleFieldValidation}>
					{this.props.tranTypes.map((type, index) => {
						return (
							<MenuItem
								key={`type-${type.code}`}
								value={type.code}>
								{`${type.name}`}
							</MenuItem>
						);
					})}
				</Select>
				</FormControl>
			</Grid>
		)
	}

    handleRepeatCheckbox() {
        //console.log("repeat option selected.")
		let _flag = !this.state.repeatFlag;
        this.setState({ repeatFlag: _flag });
    }

	renderFormFieldDate() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={'date'}
					label={this.state.transaction.date.alias}
					className={classes.textField}
					margin="normal"
					type="date"
					value={this.state.transaction.date.value}
					error={this.state.transaction.date.hasErrors.error}
					required={this.state.transaction.date.rules.required}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.date.hasErrors.message}
				/>
                {!this.props.actionUpdate && <span><Checkbox onClick={()=>this.handleRepeatCheckbox()}/>Repeat</span>}
			</Grid>
		)
	}

    handleFrequencyChange(e) {
        let f = e.target.value;
        //console.log("setting freqency: " + f)
        let d = this.state.transaction.date.value;
        let inc = (f === 'daily' ? "days" : (f === 'weekly' ? "weeks" : "months"));
        let d1 = moment(d).add(1, inc).format('YYYY-MM-DD');
        this.setState({ freqency: f, frequencyInc: inc, freqencyEndDate: d1 })
    }

    handleFrequencyEndChange(e) {
        //console.log("setting freqency end date: " + e.target.value)
        this.setState({ freqencyEndDate : e.target.value  })
    }

    renderFormFieldRepeat() {
        return (
            <Grid item md={12} lg={12} xs={12}>
				<FormControl>
				<InputLabel>Frequency:</InputLabel>
				<Select
					label='Frequency:'
					name={'frequency'}
					defaultValue={"daily"}
					onChange={this.handleFrequencyChange}>
					{this.state.frequencyList.map((item, index) => {
						return (
							<MenuItem
								key={`freq-${item.code}`}
								value={item.code}>
								{`${item.name}`}
							</MenuItem>
						);
					})}
				</Select>
                <TextField
					name={'freq_end_date'}
					label={"End Date:"}
					margin="normal"
					type="date"
					value={this.state.freqencyEndDate}
					error={this.state.transaction.date.hasErrors.error}
					required={this.state.transaction.date.rules.required}
					onChange={this.handleFrequencyEndChange}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.date.hasErrors.message}
				/>
				</FormControl>
            </Grid>
        )
    }

	renderFormFieldDesc() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={'desc'}
					label={this.state.transaction.desc.alias}
					className={classes.textField}
					margin="normal"
					value={this.state.transaction.desc.value}
					error={this.state.transaction.desc.hasErrors.error}
					required={this.state.transaction.desc.rules.required}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.desc.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderFormFieldNote() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={'note'}
					label={this.state.transaction.note.alias}
					className={classes.textField}
					margin="normal"
					value={this.state.transaction.note.value}
					error={this.state.transaction.note.hasErrors.error}
					required={this.state.transaction.note.rules.required}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.note.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderSQLError() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<Typography 
					variant="caption" 
					align="center" 
					className={classes.sqlErrorMessage}>
					{this.state.sqlErrorMessage}
				</Typography>
			</Grid>
		)
	}

	renderActions() {
		return (
			<div className={classes.newAccountModalActions}>
				<Button
					variant="contained"
                    color="primary"
                    data-cy="btnTranCancel"
					className={classes.button}
					onClick={() => this.props.onCancel()}>
					Cancel
				</Button>
				{!this.props.multiMode && !this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleAllFieldValidation}>
					Submit
				</Button>}
				{!this.props.multiMode && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleUpdateTransaction}>
					Update
				</Button>}
				{this.props.multiMode && this.props.transactions.length > 0 && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleBulkUpdateTransaction}>
					Bulk Update
				</Button>}
				{this.props.multiMode && this.props.transactions.length === 0 && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleBulkAccountUpdateTransaction}>
					Bulk Account Update
				</Button>}
			</div>
		)
	}

	renderLinearLoader() {
		return (
			this.state.actionInProgress.openLinearLoading &&
				<LinearProgress color="primary" variant="query" />
		)
	}

	renderSingleForm() {
		// if (this.props.actionUpdate) {
		// 	this.setState({transaction: this.props.transaction})
		// }
		return (
			<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
					<Grid container className={classes.newAccountModalActions} data-cy="mdlCreateNewTranGrid">
						{this.renderFormTitle()}
						{this.renderFormFieldType()}
						{this.renderFormFieldAmnt()}
						{this.renderFormFieldDate()}
						{this.state.repeatFlag && this.renderFormFieldRepeat()}
						{this.renderFormFieldDesc()}
						{this.renderFormFieldNote()}
						{this.renderSQLError()}
						{this.renderActions()}
						{this.renderLinearLoader()}
					</Grid>
			</Dialog>
		);
	}

	renderMultiForm() {
		return (
			<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
					<Grid container className={classes.newAccountModal} data-cy="mdlCreateNewTranGrid">
							{this.renderFormTitle()}
							{this.renderFormBulkFieldSelection()}
							{this.state.multiUpdateField === 'type' && this.renderFormFieldType()}
							{/* {this.renderFormFieldAmnt()} */}
							{this.state.multiUpdateField === 'date' && this.renderFormFieldDate()}
							{this.state.multiUpdateField === 'desc' && this.renderFormFieldDesc()}
							{this.state.multiUpdateField === 'note' && this.renderFormFieldNote()}
							{/* {this.renderSQLError()} */}
							</Grid>
								<Grid container className={classes.newAccountModalActions}>
								{this.renderActions()}
								{this.renderLinearLoader()}
							</Grid>
			</Dialog>
		);
	}

	render() {
		return this.props.multiMode ? this.renderMultiForm() : this.renderSingleForm();
	}
}

NewTran.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	tranTypes: PropTypes.array.isRequired,
	account: PropTypes.object.isRequired,
	isCredit: PropTypes.bool.isRequired,
	//Needed for update transaction
	actionUpdate: PropTypes.bool.isRequired,
	// transaction: PropTypes.object,
	multiMode: PropTypes.bool,
	transactions: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(NewTran)));

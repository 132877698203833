const headers = [
	'sno',
	'date',
	'curr',
	'cr',
	'dr',
	'bal',
	'qtr',
	'typeName',
	'stmt',
	'desc',
	'note',
	'account',
	'uid'
];

const headersSearch = [
	'sno',
	'date',
	'account.code',
	'desc',
	'typeName',
	'cr',
	'dr',
	'bal',
	'curr',
	'qtr',
	'stmt',
	'note',
	'link_io',
	'link_ac.code'
];

export const prepareExport = {
	exportAccountTrans : (account = 'Account', date = (new Date()).toISOString().split('.')[0], prodRelease = 'true') => {
		let params = {};

		// export these columns/headers
		params.columnKeys = headers;

		// modify the header name output
		params.processHeaderCallback = function(params) {
			if (params.column.getColId() === 'date') {
				params.column.getColDef().headerName = 'Transaction Date';
				return params.column.getColDef().headerName;
			}
			else if (params.column.getColId() === 'typeName') {
				params.column.getColDef().headerName = 'Transaction Type';
				return params.column.getColDef().headerName;
			}
			else return params.column.getColDef().headerName;
		};

		// modify value output
		params.processCellCallback = function(params) {
			// if any value is null, a empty string will be returned
			if (params.value === null) return '';
			else if (params.column.getColId() === 'date') {
				return params.value.substr(0, 10);
			}
			// else if (
			// 	params.column.getColId() === 'cr' ||
			// 	params.column.getColId() === 'dr' ||
			// 	params.column.getColId() === 'bal' 
			// 	) {
			// 	return params.value.substr(0, 10);
			// }
			// else if (
			// 	params.column.getColId() === 'fund_manager_shortname' ||
			// 	params.column.getColId() === 'retail_venture_usa' ||
			// 	params.column.getColId() === 'retail_venture_ny'
			// ) {
			// 	if (params.value === 'NA') return '';
			// 	else return params.value;
			// }
			else
				// return the param value as is for any other fields
				return params.value;
		};

		// prepare filename output
		//if (prodRelease === 'true') params.fileName = `Internal-${title}`;
		//else if (prodRelease === 'false') params.fileName = `Preview Release - ${title} (${date})`;
		params.fileName = `${account} transactions - ${date}`;

		return params;
	},

	exportSearchTrans : (account = 'All', date = (new Date()).toISOString().split('.')[0], prodRelease = 'true') => {
		let params = {};

		// export these columns/headers
		params.columnKeys = headersSearch;

		// modify the header name output
		params.processHeaderCallback = function(params) {
			if (params.column.getColId() === 'Date') return 'Transaction Date';
			else if (params.column.getColId() === 'Type') return 'Transaction Type';
			else if (params.column.getColId() === 'link_io') return 'In/Out';
			else return params.column.getColDef().headerName;
		};

		// modify value output
		params.processCellCallback = function(params) {
			// if any value is null, a empty string will be returned
			if (params.value === null) return '';
			else if (
				params.column.getColId() === 'link_io'
			) {
				if (['i', 'i?', 'iq'].includes(params.value)) return 'In';
				else if (['o', 'o?', 'oq'].includes(params.value)) return 'Out';
				else return params.value;
			}
			else
				// return the param value as is for any other fields
				return params.value;
		};

		// prepare filename output
		//if (prodRelease === 'true') params.fileName = `Internal-${title}`;
		//else if (prodRelease === 'false') params.fileName = `Preview Release - ${title} (${date})`;
		params.fileName = `Search (${account}) transactions - ${date}`;

		return params;
	},
};